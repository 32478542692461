import { css } from '@emotion/css';

import { nonMobileMediaQuery, smallMobileMediaQuery } from '../../constants';
import { m } from '../../motif/m';

export const paginationCss = css`
  align-items: center;
  display: flex;
  height: 44px;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: ${m('--spacing-l')} 0;

  ${nonMobileMediaQuery} {
    padding-block-start: ${m('--spacing-xxxl')};
  }

  li {
    display: flex;
    justify-content: center;
    position: relative;
  }
`;

export const paginationItemButtonCss = css`
  background-color: transparent;
  border: 0;
  user-select: none;
  color: ${m('--pagination-text-color')};
  cursor: pointer;
  font: inherit;
  font-size: 16px;
  height: 44px;
  letter-spacing: 0.01em;
  padding: 0;
  width: 44px;

  ${smallMobileMediaQuery} {
    min-width: 40px;
    padding-inline: ${m('--spacing-xxs')};
    width: auto;
  }
`;

export const paginationItemCss = css`
  ::after {
    border-radius: ${m('--spacing-xxs')} ${m('--spacing-xxs')} 0 0;
    bottom: 0;
    content: ' ';
    height: ${m('--spacing-xxs')};
    position: absolute;
    width: ${m('--spacing-l')};
  }

  ${nonMobileMediaQuery} {
    &:hover::after {
      background-color: ${m('--pagination-text-hover-color')};
    }
  }
`;

export const activePaginationItemCss = css`
  button {
    color: ${m('--pagination-text-active-color')};
    font-weight: 500;
  }

  ::after,
  &:hover::after {
    background-color: ${m('--pagination-text-active-underline-color')};
  }
`;

export const arrowButtonCss = css`
  *[dir='rtl'] & {
    transform: rotate(180deg);
  }
`;

export const previousButtonCss = css`
  margin-inline-end: ${m('--spacing-xxs')};

  ${nonMobileMediaQuery} {
    margin-inline-end: ${m('--spacing-l')};
  }
`;

export const nextButtonCss = css`
  margin-inline-start: ${m('--spacing-xxs')};

  ${nonMobileMediaQuery} {
    margin-inline-start: ${m('--spacing-l')};
  }
`;
