import {
  Hyperlink,
  MessageContext,
  Spinner,
  Table,
  useIsMobile,
} from '@snapchat/snap-design-system-marketing';
import isEmpty from 'lodash-es/isEmpty';
import { type FC, Fragment, useContext } from 'react';
import type { Column } from 'react-table';

import { searchTermHitDelimiter } from './constants';
import {
  emptyContainerCss,
  mobileJobContainerCss,
  spinnerContainerCss,
  tableCss,
  tableHeaderCss,
} from './styles';
import type { ModifiedJobData } from './types';

const EmptyState: FC = () => {
  const { formatMessage } = useContext(MessageContext);

  const emptyStateMessage = formatMessage({
    id: 'jobsEmptyState',
    defaultMessage: 'No results found for the selected filters.',
  });

  const viewAllOpenPositions = formatMessage({
    id: 'viewAllPositions',
    defaultMessage: 'View all open positions',
  });

  return (
    <section className={emptyContainerCss}>
      <p>{emptyStateMessage}</p>
      <br />
      <Hyperlink link="/jobs">{viewAllOpenPositions}</Hyperlink>
    </section>
  );
};

const MobileTable: FC<{ items: ModifiedJobData[] }> = ({ items }) => {
  return (
    <>
      {items.map((jobItem: ModifiedJobData, index: number) => {
        return (
          <div data-test-id="jobs-row" key={index}>
            <Hyperlink link={jobItem.title.url}>{jobItem.title.text}</Hyperlink>
            <p>{jobItem.role}</p>
            <p>{jobItem.locations}</p>
            <br />
          </div>
        );
      })}
    </>
  );
};

type JobsTableProps = {
  isLoading: boolean;
  jobs: Record<string, ModifiedJobData[]>;
  filterKey: string;
};

/**
 * Given a string with delimited search term hits, return an array of React elements where the
 * search term hits are wrapped in a <mark> tag with a bold font weight.
 */
function htmlStringToReact(htmlString: string) {
  return htmlString.split(searchTermHitDelimiter).map((fragment, index) =>
    index % 2 === 0 ? (
      <span key={index}>{fragment}</span>
    ) : (
      <mark key={index}>
        <b>{fragment}</b>
      </mark>
    )
  );
}

const getSubRow = (value: object) => {
  const job = value as ModifiedJobData;

  // If search string found in title, no need to show the job description fragment
  if (job.titleSearchResult) {
    return null;
  }

  // If search string found in job description, show the job description fragment to contextually
  // highlight the search string
  if (job.descriptionSearchResult) {
    return <p>{htmlStringToReact(job.descriptionSearchResult)}</p>;
  }

  // Otherwise, no search string found in title or job description so return null indicating no subrow
  // is to be rendered
  return null;
};

/** Returns Job Table Component */
export const JobsTable: FC<JobsTableProps> = ({ isLoading, jobs, filterKey }) => {
  const isMobile = useIsMobile();
  // These values are fine harcoded because we purposely do not localize this page
  const tableColumns: Column<ModifiedJobData>[] = [
    {
      Header: 'Role',
      accessor: (row: ModifiedJobData) => {
        // If search string found in title, render the title with the search string highlighted,
        // otherwise, render the title as is
        const hyperLinkBody = row.titleSearchResult ? (
          htmlStringToReact(row.titleSearchResult)
        ) : (
          <span>{row.title.text}</span>
        );

        return <Hyperlink link={row.title.url}>{hyperLinkBody}</Hyperlink>;
      },
    },
    {
      Header: 'Team',
      accessor: 'role',
    },
    {
      Header: 'Type',
      accessor: 'employment_type',
    },
    {
      Header: 'Location',
      accessor: 'locations',
    },
  ];

  if (isLoading) {
    return (
      <div className={spinnerContainerCss}>
        <Spinner size={40} />
      </div>
    );
  }

  if (isEmpty(jobs)) {
    return <EmptyState />;
  }

  return (
    <>
      {Object.keys(jobs).map((bucket: string, i) => {
        const currentBucket = jobs[bucket] ?? [];
        return (
          <Fragment key={`${filterKey}_${i}`}>
            <h5 className={tableHeaderCss}>{bucket}</h5>
            {isMobile ? (
              <div className={mobileJobContainerCss}>
                <MobileTable items={currentBucket as ModifiedJobData[]} />
              </div>
            ) : (
              <Table<ModifiedJobData>
                className={tableCss}
                data={currentBucket}
                columns={tableColumns}
                renderSubRow={getSubRow}
              />
            )}
          </Fragment>
        );
      })}
    </>
  );
};
