import { css } from '@emotion/css';

import { nonMobileMediaQuery } from '../../../constants';
import { androidLinearOutSlowIn } from '../../../constants/animation';
import { m } from '../../../motif/m';
import {
  globalNavGroupAnimationDurationMs,
  globalNavNegativeOuterMargin,
  globalNavOuterMargin,
  nextLevelLineHeightCssVar,
} from '../GlobalHeader.constants';

export const childrenCountCssVarName = '--children-count';
const animateInDetailsCss = css`
  overflow: hidden;

  height: 0;
  opacity: 0;

  transition-property: opacity, height;
  transition-duration: ${globalNavGroupAnimationDurationMs}ms;
  transition-timing-function: ease-in;

  details[data-state='on'] &,
  details[data-state='turning-on'] & {
    height: calc(var(${nextLevelLineHeightCssVar}) * var(${childrenCountCssVarName}));
    opacity: 1;

    transition-timing-function: ${androidLinearOutSlowIn};
  }
`;

export const itemListCss = css`
  list-style-type: none;
  padding: 0 0 0 16px;
  margin: 0;

  *[dir='rtl'] & {
    padding-left: initial;
    padding-right: 12px;
  }

  ${nonMobileMediaQuery} {
    ${animateInDetailsCss}
  }
`;

// Force active styles on Global Nav Group when Global Nav is open
export const globalNavGroupActiveCss = css`
  & > summary {
    color: ${m('--global-header-item-active-color')};
  }
  &::before {
    visibility: visible;
  }
`;

export const globalNavGroupCss = css`
  transition: color 0.125s;
  white-space: nowrap;
  scroll-margin: ${globalNavOuterMargin};

  /* Hide the text that doesn't fit. */
  > summary {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:hover,
  &:active,
  &:focus-within,
  [data-state='on'] > summary {
    color: ${m('--global-header-item-active-color')};
  }

  /** Displays the knob on active and hover in desktop mode only */
  ${nonMobileMediaQuery} {
    /* Move the summary over so we can position the knob inside */
    left: ${globalNavNegativeOuterMargin};
    padding-left: ${globalNavOuterMargin};
    width: calc(${globalNavOuterMargin} + 100%);

    *[dir='rtl'] & {
      left: unset;
      padding-left: unset;
      right: ${globalNavNegativeOuterMargin};
      padding-right: ${globalNavOuterMargin};
    }

    &::before {
      content: ' ';
      background-color: ${m('--global-header-item-hover-color')};
      position: absolute;
      left: 0;
      top: 10px;
      width: 4px;
      height: 32px;
      border-start-end-radius: ${m('--border-radius-s')};
      border-end-end-radius: ${m('--border-radius-s')};
      visibility: hidden;
    }

    *[dir='rtl'] &::before {
      left: unset;
      right: 0;
    }

    &:focus-within::before,
    &:hover::before,
    &:active::before,
    details[data-state='on'] > &::before {
      visibility: visible;
    }
  }
`;

export const detailsHeightAnimationDesktopCss = css`
  position: relative;
`;
