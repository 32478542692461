import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

const lightThemeDefaultVars = {
  '--side-navigation-desktop-box-shadow': m('--box-shadow-s'),
  '--side-navigation-desktop-fg-active-color': m('--fg-color'),
  '--side-navigation-mobile-bar-bg-color': m('--neutral-v200'),
  '--side-navigation-mobile-bar-fg-color': m('--neutral-v700'),
  '--side-navigation-mobile-bg-color': m('--neutral-v150'),
  '--side-navigation-mobile-fg-active-color': m('--neutral-v700'),
  '--side-navigation-mobile-fg-color': m('--neutral-v600'),
  '--side-navigation-mobile-toggler-icon-color': m('--neutral-v700'),
} as const;

export const blackThemeSideNavVars: MotifComponentProps<MotifComponent.SIDE_NAVIGATION> = {
  '--side-navigation-desktop-bar-bg-color': '#222425',
  '--side-navigation-desktop-box-shadow': '0px 0px 0px 0 rgba(0, 0, 0, 0)',
  '--side-navigation-desktop-fg-active-color': m('--primary-v100'),
  '--side-navigation-mobile-bar-bg-color': m('--neutral-v650'),
  '--side-navigation-mobile-bar-fg-color': m('--neutral-v0'),
  '--side-navigation-mobile-bg-color': m('--neutral-v150'),
  '--side-navigation-mobile-fg-active-color': m('--neutral-v700'),
  '--side-navigation-mobile-fg-color': m('--neutral-v600'),
  '--side-navigation-mobile-toggler-icon-color': m('--neutral-v0'),
};

export const whiteThemeSideNavVars: MotifComponentProps<MotifComponent.SIDE_NAVIGATION> = {
  ...lightThemeDefaultVars,
  '--side-navigation-desktop-bar-bg-color': m('--neutral-v0'),
};

export const grayThemeSideNavVars: MotifComponentProps<MotifComponent.SIDE_NAVIGATION> = {
  ...lightThemeDefaultVars,
  '--side-navigation-desktop-bar-bg-color': m('--neutral-v150'),
};

export const yellowThemeSideNavVars: MotifComponentProps<MotifComponent.SIDE_NAVIGATION> = {
  ...lightThemeDefaultVars,
  '--side-navigation-desktop-bar-bg-color': m('--primary-v100'),
};
