import { Site } from './sites';

export const productionDomains: Record<Site, string> = {
  [Site.A523]: '523.snap.com',
  [Site.AR]: 'ar.snap.com',
  [Site.ARCADIA]: 'www.arcadiacreativestudio.com',
  [Site.AVALON]: 'www.spectacles.com',
  [Site.CAREERS]: 'careers.snap.com',
  [Site.CHEERIOS]: 'www.pixy.com',
  [Site.CITIZEN]: 'citizen.snap.com',
  [Site.CREATORS]: 'creators.snap.com',
  [Site.DIVERSITY]: 'belonging.snap.com',
  [Site.EDUCATORS]: 'educators.snapchat.com',
  [Site.ENG_BLOG]: 'eng.snap.com',
  [Site.EXPERIENCE]: 'experience.snap.com',
  [Site.FOR_BUSINESS]: 'forbusiness.snapchat.com',
  [Site.NEWSROOM]: 'newsroom.snap.com',
  [Site.PARENTS]: 'parents.snapchat.com',
  [Site.SANDBOX]: 'sandbox-production-dot-entapps-web-dev.gae.sc-corp.net',
  [Site.SNAP]: 'www.snap.com',
  [Site.TRUST]: 'values.snap.com',
};
